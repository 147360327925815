import React, { Component } from "react";
import moment from 'moment';
import * as tabConst from '../../../utils/TableConstanten'
import Select from "react-select";
import ConfirmationDialog from "../../ConfirmationDialog";
import VorsorgeartSelector from "../../../config/VorsorgeartSelector";
import UntersuchungsFristen from "../../../config/UntersuchungsFristen";

class UntersuchungsergebnissForm extends Component {
 
    constructor(props){
        super(props);
        this.default = {
            editForm: false,
            resourceId: null, 
            firmenSelectOptions: [{index: "firma", value: 'ID', label: "Name" }],
            selectedFirmenOption: [],
            vorsorgeSelectOptions: [{index: "vor", value: 'ID', label: "Name" }],
            selectedVorsorgeOption: [],
            mitarbeiterSelectOptions: [{index: "mit", value: 'ID', label: "Name" }],
            selectedMitarbeiterOption: [],
            beurteilungSelectOptions: [<option key="99999" value="" selected disabled hidden> bitte Wählen </option>],
            vorsorgeArtSelectOptions: [<option key="0" value="0"> nicht Vorhanden </option>],
            dateienDownloadLinks: [],
            oldData: {},
            data:{
                art: "EU",
                gemacht_am: moment().format('YYYY-MM-DD'),
                naechster_termin: moment().format('YYYY-MM-DD'),
                beurteilung: "",
                kommentar: "",
                mitarbeiterId: 0,
                firmenkarteiId: 0,                
                firmenId: global.filterFirmenId,
            },
            datei: {
                name: "",
                datei: "",
                untersuchungenId: 0,
            },
            isConfirmationDialogOpen: false
            
        };

        this.functions = props.functions

        this.handleFirmenSelectChange = this.handleFirmenSelectChange.bind(this);
        this.handleVorsorgeSelectChange = this.handleVorsorgeSelectChange.bind(this);
        this.handleMitarbeiterSelectChange = this.handleMitarbeiterSelectChange.bind(this);
        this.onInputchange = this.onInputchange.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this);
        this.checkSave = this.checkSave.bind(this);

        this.state = {...this.default};
    }

    async validate(props) {
        let properties = props.schema.properties;
        // console.log('props', props);

        if(props.resourceId){ //Edit Form
            // console.log("Edit Form!")
            this.setState({
                editForm: true,
                resourceId: props.resourceId,
                isConfirmationDialogOpen: false,
                datei: this.default.datei
            })
            let untersuchung = await this.fetchData("untersuchungen/"+props.resourceId);

            //History suchen
            let hist_unt = await this.fetchData(`untersuchungen/hist?status=H&mitarbeiterId=${untersuchung.mitarbeiterId}&firmenkarteiId=${untersuchung.firmenkarteiId}`)
            if(hist_unt.length > 0){
                props.functions.showHistoryButton(true);
                props.functions.setHistoryData(hist_unt);
            }

            this.setState({data: { ...this.state.data, 
                art: untersuchung.art,
                beurteilung: untersuchung.beurteilung,
                kommentar: untersuchung.kommentar,
                gemacht_am: moment(untersuchung.gemacht_am).format('YYYY-MM-DD'),
                naechster_termin: moment(untersuchung.naechster_termin).format('YYYY-MM-DD'),
                firmenkarteiId: untersuchung.firmenkarteiId,
                mitarbeiterId: untersuchung.mitarbeiterId,
                firmenId: await this.getFirmenIdfromMitarbeiterId(untersuchung.mitarbeiterId),
            }}, () => this.setState({oldData: this.state.data}), () => this.checkSave); //wir merken uns die alten Daten

            //Im Edit zeigen wir noch die Zugeordneten Dateien An
            this.setState({dateienDownloadLinks: await this.setDateienDownloadLinks()});
        }
        if(props.helper) {//wir können grundbefüllung über helper machen
            // console.log('HELPER!!!!', props.helper)
            this.setState({data: { ...this.state.data, 
                firmenkarteiId: props.helper['firmenkartei.id'],
                mitarbeiterId: props.helper['mitarbeiter.id'],
                zuordnungId: props.helper['zuordnung.id'],
                untersuchungsId: props.helper['id'],
                firmenId: await this.getFirmenIdfromMitarbeiterId(props.helper['mitarbeiter.id'])
            }});
        } 

        this.setSelectBoxOptions(properties);
    }

    async setSelectBoxOptions(properties){
        //firmenSelectOptions    
        this.setState({ firmenSelectOptions: await this.functions.setFirmenSelectOptions()}, () => {
            //wenn der Init = 0 ist haben wir kein defaultValue
            // console.log(this.state.data.firmenId, this.state.firmenSelectOptions[0])
            if(this.state.data.firmenId === 0){
                this.setState({ data: { ...this.state.data, firmenId: this.state.firmenSelectOptions[0].value },
                                selectedFirmenOption: this.state.firmenSelectOptions[0]
                });
            }else{
                // eslint-disable-next-line array-callback-return
                this.state.firmenSelectOptions.map(opt => {
                    // console.log('test', this.state.data.firmenId, opt.value, opt.label)
                    if(opt.value === this.state.data.firmenId){
                        this.setState({ selectedFirmenOption: opt })
                    }
                })
            }
        });    

        //vorsorgeSelectOptions
        this.setState({ vorsorgeSelectOptions: await this.setVorsorgenWithFirmenId(this.state.data.firmenId)}, async () => {
            //mitarbeiterSelectOptions
            this.setState({ mitarbeiterSelectOptions: await this.setMitarbeiterSelectOptions(this.state.data.firmenId, this.state.data.firmenkarteiId)})
            
            //beurteilung Select box fuellen
            this.setState({beurteilungSelectOptions: await this.setBeurteilungsSelectOptions(properties)});        
        });

        //Art Select box fuellen
        this.setState({vorsorgeArtSelectOptions: [this.default.vorsorgeArtSelectOptions]}) //einmal leeren vor fuellung
        properties.art.enum.map( (element, idx) => 
            this.setState({vorsorgeArtSelectOptions: [...this.state.vorsorgeArtSelectOptions, <option key={"vas-"+idx} value={ element }> { element } </option>]})
        );
    }

    /** 
     * beurteilung Select box fuellen
     * Achtung, je nach Vorsorgeart unterschiedlich füllen siehe #170
    **/
    async setBeurteilungsSelectOptions(properties){
        let selektors = VorsorgeartSelector[this.state.selectedVorsorgeOption['data-vorsorgeart']] || VorsorgeartSelector['default'];
        // console.log("SetBeurteilungSelectOptions", selektors)
        let tmp = [...this.default.beurteilungSelectOptions];
        tmp.push(
            ...properties.beurteilung.enum
              .filter(element => selektors.includes(element)) // Nur die Elemente auswählen, die in 'selektors' enthalten sind
              .map((element, idx) => (
                <option key={"b-" + idx} value={element}>{element}</option>
              ))
          );
        return tmp;
    }

    async getFirmenIdfromMitarbeiterId(mitarbeiterId){
        let mitarbeiter = await this.fetchData('mitarbeiter/'+mitarbeiterId);
        // console.log('mit', mitarbeiter)
        return mitarbeiter.firmenId;
    }

    async setDateienDownloadLinks(){
        let tmp = []; 
        let dateien = await this.fetchData("datei", `?untersuchungenId=${this.state.resourceId}`);
        for(let key in dateien){
            let datei = dateien[key];
            // console.log(typeof datei, datei)
            tmp.push(
                <div key={datei.id} className="col-auto text-center pt-3 mx-3  datei">
                    <a href={global.api_url+"datei/"+datei.id+"?authorization="+global.auth.authToken.token+"&binary=true"} rel="noreferrer" target="_blank">
                        <i className="far fa-file-alt"/>
                        <p className="datei-name">{ datei.name }</p>
                        <p style={ {fontSize: ".8em"} }>({ moment(datei.updatedAt).format('DD.MM.YYYY - hh:mm:ss') })</p>
                    </a>
                    <i className="fas fa-times" data-datei={JSON.stringify(datei)} onClick={this.toggleConfirmDialog}></i>
                </div>
            );
        }
        return tmp;
    }

    toggleConfirmDialog(e){
        let obj = e.target.dataset.datei ? JSON.parse(e.target.dataset.datei) : e.target.dataset.datei;
        this.setState({isConfirmationDialogOpen: !this.state.isConfirmationDialogOpen, datei: obj});
    }

    deleteDatei(){
        // console.log('delete datei:', this.state.datei)
        let headers = {
            method: 'delete',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': global.auth.authToken.token 
            } 
        }
        fetch(global.api_url + tabConst.DATEI + '/'+this.state.datei.id, headers).then(() => {
            this.validate(this.props);
        });
    }

    async setMitarbeiterSelectOptions(firmenId, firmenVorsorgeId, setDefault = false){
        let filter = "?firmenId="+firmenId+"&firmenkarteiId="+firmenVorsorgeId;
        let mitarbeiter = await this.fetchData("report/mitarbeiter_vorsorgen", filter);
        // console.log('fetch mitarbeiter ',typeof mitarbeiter, mitarbeiter)
        let tmp = [];
        for( let key in mitarbeiter){
            let mitarbeit = mitarbeiter[key];
            tmp.push({ value: mitarbeit.id, label: mitarbeit.mitarbeiterName })
            
            if(!setDefault && mitarbeit.id === this.state.data.mitarbeiterId){
                this.setState({ selectedMitarbeiterOption: tmp[key] })
            }
        }
        //wenn der Init = 0 ist haben wir kein defaultValue
        if(setDefault || (!this.state.editForm && !this.props.helper)){
            // console.log('tmp:', tmp[0])
            this.setState({ data: { ...this.state.data, mitarbeiterId: null },
                            selectedMitarbeiterOption: [] });
        }
        return tmp;
    }

    async setVorsorgenWithFirmenId(firmenId, setDefault = false){
        let vorsorgen = await this.fetchData(tabConst.FIRMENKARTEI+'?firmenId='+firmenId);
        let tmp = [];
        for (const key in vorsorgen.data){
            let vorsorge = vorsorgen.data[key];
            await this.fetchData(tabConst.VORSORGEN+'/'+vorsorge.vorsorgekarteiId).then( vorsorgekartei => {
                // console.log('kartei:',vorsorgekartei.titel, ' firm:',vorsorge.titel, ' id:', vorsorge.id, 'ablauf:', vorsorgekartei.ablaufsfrist);
                // console.log('kartei', vorsorgekartei.titel, vorsorge.bg_grundsatz)
                let titel = vorsorge.titel ? vorsorge.titel : vorsorgekartei.titel;  
                tmp.push({
                    value: vorsorge.id, 
                    index: "v-"+vorsorge.id, 
                    label: titel, 
                    'data-ablauf': vorsorgekartei.ablaufsfrist , 
                    'data-vorsorgeart': vorsorgekartei.vorsorgeart,
                    'data-bg': vorsorgekartei.bg_grundsatz
                })
            });
            if(!setDefault && (vorsorge.id === this.state.data.firmenkarteiId)){
                this.setState({selectedVorsorgeOption: tmp[key]})
            }
        }
        //wenn default = true resetten wir auch das select feld
        if(setDefault){
            this.setState({ selectedVorsorgeOption: [] })
        }
        if(!this.state.editForm) this.refreshNaechsterTerminDate();
        return tmp;
    }

    refreshNaechsterTerminDate(gemacht_datum = moment()){
        //default Ablauftime
        let ablaufTime = this.state.selectedVorsorgeOption['data-ablauf'];

        // NU-Frist in Abhängigkeit der Untersuchungsart (EU/NU) #170
        let vorsorgeart = this.state.selectedVorsorgeOption['data-vorsorgeart'];
        let fristen = UntersuchungsFristen[vorsorgeart] || { "EU": ablaufTime, "NU": ablaufTime };
     
        // console.log('refresh time', ablaufTime, vorsorgeart, fristen)
        
        let next = gemacht_datum.add(fristen[this.state.data.art], 'M').format('YYYY-MM-DD').toString()
        // console.log('next ',next)
        this.setState({ data: {...this.state.data, naechster_termin: next}})
    }

    async fetchData(resourceName, filter){
        let headers = {
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': global.auth.authToken.token 
            } 
        }
        let query = filter ? filter : "";
        return fetch(global.api_url + resourceName + query, headers)
        .then(res => res.json())
        .catch(err => console.log(err))
    }

    componentDidMount() {
      this.validate(this.props);
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.validate(newProps)
        }
    }

    /**
     * wenn Firma geändert wird, muss der select der Vorsorgen und MItarbeiter aktualisiert werden. 
     * @param {*} e 
     */
    async handleFirmenSelectChange(e){
         // console.log('test', e)
        //  console.log('inpChan', 'firmenId', e.id )
         this.setState({selectedFirmenOption: e}, 
            () => this.setState({ data: {...this.state.data, firmenId: e.value }}, async()=> {
                //firmenkartei Options aktualisieren #mit default to true, da wir sie leer haben möchten
                this.setState({ vorsorgeSelectOptions: await this.setVorsorgenWithFirmenId(e.value, true)}, 
                    async () => { this.setState({ mitarbeiterSelectOptions: await this.setMitarbeiterSelectOptions(this.state.data.firmenId, this.state.data.firmenkarteiId, true )})
                })
            })
        );
    }

    async handleVorsorgeSelectChange(e){
        //zuordnungen aktualisieren
        // console.log('test', e)
        // console.log('inpChan', 'vorsorgeSelected', e.id )
        this.setState({selectedVorsorgeOption: e}, 
            () => this.setState({ data: {...this.state.data, firmenkarteiId: e.value }}, 
                async () => { 
                    if(!this.state.editForm) this.refreshNaechsterTerminDate(); //refresh date wenn kein Edit!+
                    this.setSelectBoxOptions(this.props.schema.properties)
                    this.checkSave();
                }
        ));
    }

    async handleMitarbeiterSelectChange(e){
        // console.log('inpChan', 'mitarbeiterId', e )
        this.setState({ selectedMitarbeiterOption: e,
                        data: {...this.state.data, mitarbeiterId: e.value} }, () => this.checkSave());
    }

    onFileSelected=event=>{
        // console.log(event.target.files[0])
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.addEventListener("load", () => {
            // console.log(JSON.stringify(reader))
            this.setState({datei: {
                name: file.name,
                datei: reader.result,
                untersuchungenId: 0
            }}, () => this.checkSave(true) );//nach upload datei -> save true
        }, false);

        if (file){
            reader.readAsDataURL(file);
        } 
    }
    onInputchange(event){
        this.setState({data: {...this.state.data, [event.target.name]: event.target.value  }}, () => {
            // console.log('inpChan', event.target.name, event.target.value, this.state.data.gemacht_am )
            if(['gemacht_am', 'art'].includes(event.target.name)){
                this.refreshNaechsterTerminDate(moment(this.state.data.gemacht_am));
            }
            this.checkSave()
        });
    }

    put_post_fetch(method, url){
        // console.log('save this:' ,this.state.data)
        let req = {
            method: method,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': global.auth.authToken.token 
            },
            body: JSON.stringify(this.state.data)
        }; 
        return fetch(url, req)
          .then(res => res.json())
          .then(ret => {
            console.log("fetch", ret)
            let data = ret.data;
            if(data.status && data.status !== 200){
                return data;
            }
            let datei = {...this.state.datei};
            datei.untersuchungenId = data.id ? data.id : this.state.resourceId;
            
            if(datei.untersuchungenId && datei.datei !== ""){
                fetch(global.api_url +'datei', {
                    method: 'POST',
                    headers: req.headers,
                    body: JSON.stringify(datei)
                }).then(data => console.log("Datei gespeichert", data)
                ).catch(err => {
                    console.log("POST Datei", err);
                });
            }
          })
          .catch(err => {
            console.log("Catch", err)
            return err;
          });
    }

    validateForm(){
        let niceNames = {
            'firmenkarteiId': "Firma",
            'art': "Art",
            'mitarbeiterId': "Mitarbeiter",
            'gemacht_am': "durchgeführt",
            'beurteilung': "Beurteilung",
            'naechster_termin': "Nachuntersuchungs Termin"
        }
        let formData = this.state.data;
        const newErrors = {};
        const form = document.getElementById('UntersuchungsergebnisseForm');
        const requiredFields = Array.from(form.querySelectorAll('[required]'));
    
        const requiredFieldNames = requiredFields.map(field => field.name);
        // console.log('Required fields:', requiredFieldNames);
        // firmenkarteiId', 'art', 'mitarbeiterId', 'gemacht_am', 'beurteilung', 'naechster_termin'

        // console.log("Dat: ", formData);
        requiredFieldNames.forEach(field => {
            // console.log("Data:", formData[field]);  
            switch (field) {
                case "firmenkarteiId":
                case "mitarbeiterId":
                    if(formData[field] === 0){
                        newErrors[field] = {label: niceNames[field], status: "Bitte einen Wert auswählen."}
                    }  
                    break;
                case "beurteilung":
                case "art":
                    if(formData[field] === ""){
                        newErrors[field] = {label: niceNames[field], status: "Bitte einen Wert auswählen."}
                    }  
                    break;

                case "gemacht_am":
                case "naechster_termin":
                    if(!(/^\d{4}-\d{2}-\d{2}$/.test(formData[field])) ){
                        newErrors[field] = {label: niceNames[field], status: "Bitte ein gültiges Datum eingeben."}
                    }
                    break;
                
                default:
                        // console.log(field);    
                    break;
            }
        });

        return {status: Object.keys(newErrors).length === 0, errors: newErrors};
    }

    saveOrUpdate(){
        console.log("Save this: ", this.state.data, this.state.oldData);
        //Form Validieren!!!
        let {status, errors} = this.validateForm();
        if(!status){
            let error = new Error("Validation failed");
            error.details = errors
            throw error;
        }

        //Update oder Save?
        const url = this.state.editForm
        ? global.api_url + 'untersuchungen/' + this.state.resourceId
        : global.api_url + 'untersuchungen';
  
        const method = this.state.editForm ? "PUT" : "POST";
        return this.put_post_fetch(method, url);
    }

    checkSave(status = false){
        // console.log("Made Change?", this.state.data, this.state.oldData)
        if(status || !(this.deepEqual(this.state.data,this.state.oldData)) ){
            //allow save
            this.functions.disableSaveButton(false);
        }else {
            //no save!
            this.functions.disableSaveButton(true);
        }
    }
    deepEqual(obj1, obj2) {
        // Wenn die Objekte nicht denselben Typ haben, sind sie nicht gleich
        if (typeof obj1 !== typeof obj2) {
            return false;
        }
    
        // Wenn beide Objekte nicht vom Typ "object" sind, können wir ihre Werte direkt vergleichen
        if (typeof obj1 !== 'object' || obj1 === null) {
            return obj1 === obj2;
        }
    
        // Wenn die Anzahl der Schlüssel unterschiedlich ist, sind die Objekte nicht gleich
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        if (keys1.length !== keys2.length) {
            return false;
        }
    
        // Vergleiche die Werte jedes Schlüssels rekursiv
        for (const key of keys1) {
            if (!this.deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        // Wenn alle Schlüssel und ihre Werte übereinstimmen, sind die Objekte gleich
        return true;
    }

    render(){
        return (<>
            <form id="UntersuchungsergebnisseForm">
            {/* Firma */}
            <div className="form-group">
                <label htmlFor="firmenId" className="control-label">Firma</label> 
                <Select 
                required = {true}
                isDisabled = {this.state.editForm}
                valueKey="id"
                instanceId = "firma"
                placeholder = "Auswählen"
                options={ this.state.firmenSelectOptions} 
                value= { this.state.selectedFirmenOption }
                name="firmenId"
                className="basic-multi-select"
                onChange={ this.handleFirmenSelectChange }
                theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ffeebe',
                      primary: '#ffd370',
                    },
                  })}
                />
            </div>
            {/* Vorsorge */}
            <div className="form-group">
                <label htmlFor="firmenkarteiId" className="control-label">Vorsorge/Eignung</label> 
                <Select 
                required={true}
                isDisabled = {this.state.editForm}
                valueKey="id"
                placeholder = "Auswählen"
                options={ this.state.vorsorgeSelectOptions} 
                value= { this.state.selectedVorsorgeOption }
                name="firmenkarteiId"
                className="basic-multi-select"
                onChange={ this.handleVorsorgeSelectChange }
                theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ffeebe',
                      primary: '#ffd370',
                    },
                  })}
                />
            </div>
            {/* Vorsorge Art */}
            <div className="form-group">
                <label htmlFor="art" className="control-label">Art</label> 
                <select id="art" name="art" className="form-control" value={this.state.data.art} 
                    required={true} 
                    onChange={this.onInputchange}
                    >
                    { this.state.vorsorgeArtSelectOptions }
                </select>
            </div>
            {/* Mitarbeiter */}
            <div className="form-group">
                <label htmlFor="mitarbeiter" className="control-label">Mitarbeiter</label> 
                <Select 
                required={true}
                isDisabled = {this.state.editForm}
                placeholder = "Auswählen"
                options={ this.state.mitarbeiterSelectOptions} 
                value= { this.state.selectedMitarbeiterOption }
                name="mitarbeiterId"
                className="basic-multi-select"
                onChange={ this.handleMitarbeiterSelectChange }
                theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#ffeebe',
                      primary: '#ffd370',
                    },
                  })}
                />
            </div>
            {/* Gemacht am */}
            <div className="form-group">
                <label htmlFor="gemacht_am" className="control-label">durchgeführt</label> 
                <input id="gemacht_am" name="gemacht_am" type="date" className="form-control" 
                    value={ this.state.data.gemacht_am } 
                    onChange={this.onInputchange} 
                    required={true}/>
            </div>
            {/* Beurteilung */}
            <div className="form-group">
                <label htmlFor="beurteilung" className="control-label">Beurteilung</label> 
                <select required={true} id="beurteilung" name="beurteilung" className="form-control" 
                    value={this.state.data.beurteilung} 
                    onChange={this.onInputchange}
                    >
                    { this.state.beurteilungSelectOptions }
                </select>
            </div>
            {/* nächster Termin */}
            <div className="form-group">
                <label htmlFor="naechster_termin" className="control-label">Nachuntersuchungs Termin</label> 
                <input required={true} id="naechster_termin" name="naechster_termin" type="date" className="form-control" 
                    value={ this.state.data.naechster_termin } 
                    onChange={this.onInputchange}
                />
            </div>
            {/* Kommentar */}
            <div className="form-group">
                <label htmlFor="kommentar" className="control-label">Kommentar</label> 
                <textarea id="kommentar" name="kommentar" cols="40" rows="3" className="form-control" 
                    value={this.state.data.kommentar} 
                    onChange={this.onInputchange}
                ></textarea>
            </div>
            {/* Datei */}
            <div className="form-group">
                <label htmlFor="file" className="control-label">Bescheinigung hochladen <em className="small">(maximal 12MB pro Datei)</em>
                </label> 
                <input type="file" name="file" className="form-control" onChange={this.onFileSelected}/>
            </div>
            <div className="form-group">
                <label htmlFor="dateien" className="control-label">Bescheinigungen</label>
                <div id="dateien" name="dateien" className="row">
                { this.state.dateienDownloadLinks }
                </div>
                <ConfirmationDialog
                    isOpen={this.state.isConfirmationDialogOpen}
                    title="Datei löschen - Bestätigung erforderlich"
                    message={`Bist du sicher, dass du die Datei: <b>${this.state.datei ? this.state.datei.name : 'undefined'}</b> undwiederruflich löschen möchtest?`}
                    onConfirm={this.deleteDatei.bind(this)}
                    onClose={this.toggleConfirmDialog}
                />
            </div>
            {/* <div>
                <label> { JSON.stringify(this.state.data) } </label>
            </div> */}
        </form>
        </>
        );
    }
}
export default UntersuchungsergebnissForm;