import React, { Component } from "react";
import { fetchApi } from "../../../utils/DatabaseHelper";
import * as tabConst from '../../../utils/TableConstanten';
import BenutzerForm from "./BenutzerForm";
import FirmenVorsorgeForm from "./FirmenVorsorgeForm";
import MitarbeiterForm from "./MitarbeiterForm";
import TaetigkeitsbereichForm from "./TaetigkeitsbereichForm";
import UntersuchungsergebnissForm from "./UntersuchungsergebnissForm";


//Filer auf welche resourceName wir springen
const IndividualForms = [
    tabConst.UNTERSUCHUNGEN,
    tabConst.MITARBEITER,
    tabConst.ZUORDNUNG,
    tabConst.FIRMENKARTEI,
    tabConst.BENUTZER,
    tabConst.MITARBEITER_UEBERSICHT
];

class IndividualForm extends Component {
 
    constructor(props){
        super(props);
        this.state = { };

        this.indvFormRef = React.createRef();

        this.functions = {
            fetchData: this.fetchData,
            setFirmenSelectOptions: this.setFirmenSelectOptions,
            showHistoryButton: props.functions.showHistoryButton,
            setHistoryData: props.functions.setHistoryData,
            disableSaveButton: props.functions.disableSaveButton,
        }
    }

    //weiterleitung an form
    saveOrUpdate(){
        return this.indvFormRef.current.saveOrUpdate().then((res) => {
            // console.log('prom ret', res)
            return res
        });
    }

    validate(props){
        // console.log("IndForm vali", props);
        switch (props.resourceName) {
            case tabConst.UNTERSUCHUNGEN:
            case tabConst.MITARBEITER_UEBERSICHT:
                this.setState({
                    form: <UntersuchungsergebnissForm
                        resourceId={props.resourceId} 
                        resourceName={tabConst.UNTERSUCHUNGEN} 
                        schema={props.schema} 
                        ref = { this.indvFormRef }
                        functions = { this.functions }
                        helper = {props.helper}
                    />
                });
                break;
            case tabConst.MITARBEITER:
                this.setState({
                    form: <MitarbeiterForm
                        resourceId={props.resourceId} 
                        resourceName={props.resourceName} 
                        schema={props.schema} 
                        ref = { this.indvFormRef }
                        functions = { this.functions }
                   />
                })
                break;
            case tabConst.ZUORDNUNG:
                this.setState({
                    form: <TaetigkeitsbereichForm
                        resourceId={props.resourceId} 
                        resourceName={props.resourceName} 
                        schema={props.schema} 
                        ref = { this.indvFormRef }
                        functions = { this.functions }
                   />
                })
                break;
            case tabConst.FIRMENKARTEI:
                this.setState({
                    form: <FirmenVorsorgeForm
                        resourceId={props.resourceId} 
                        resourceName={props.resourceName} 
                        schema={props.schema} 
                        ref = { this.indvFormRef }
                        functions = { this.functions }
                   />
                })
                break;
            case tabConst.BENUTZER:
                this.setState({
                    form: <BenutzerForm
                        resourceId={props.resourceId} 
                        resourceName={props.resourceName} 
                        schema={props.schema} 
                        ref = { this.indvFormRef }
                        functions = { this.functions }
                   />
                })
                break;
        
            default:
                this.setState({ form: <div>Not defined...</div>})
                break;
        }
    }

    componentDidMount() {
        this.validate(this.props);
    }
    
    componentWillUnmount(){
        switch (this.props.resourceName) {
            case tabConst.UNTERSUCHUNGEN:
                this.props.functions.showHistoryButton(false);
                this.props.functions.disableSaveButton(true);
                break;
            default:
                // console.log("nix zu tun");
                break;
        }
    }

    render(){
        return (
            <div className="formWrapper individual">
                { this.state.form }
            </div>
        );
    }


    /**
     * Funktionen die alle gleich haben
     * 
     * Wichtig, this.function updaten
     */

    async fetchData(resourceName, id = '', filter){
        let query = filter ?? "";
        return await fetchApi(resourceName, id, query).then(data => { return data })
    }

    async setFirmenSelectOptions(){
        let response = await this.fetchData(tabConst.FIRMEN, "");
        let firmen = response.data;
        let tmp = Object.keys(firmen).map( (key) => {
            // console.log(key, firmen[key])
            let firma = firmen[key];
            return {value: firma.id, label: firma.name.toString() }
        });
        return tmp;
    }

}
export {IndividualForm, IndividualForms};
